import bestellverteilungNachLand from '../assets/dashboard/faie/bestellverteilung-nach-land.png';
import monatlicherUmsatzvergleich from '../assets/dashboard/faie/monatlicher-umsatzvergleich.png';
// import top10Produkte from '../assets/dashboard/faie/top-10-produkte.png';
import zahlungsmethodenUndUmsatz from '../assets/dashboard/faie/zahlungsmethoden-und-umsatz.png';
import geschlechterverteilungDerKunden from '../assets/dashboard/faie/geschlechterverteilung-der-kunden.png';
import topProdukteNachUmsatz from '../assets/dashboard/faie/top-produkte-nach-umsatz.png';
import pic from '../assets/dashboard/faie/123.png';
import monthlyRevenue from '../assets/dashboard/wangen/monthly-revenue-from-2021-to-may-2024.png';
import customerLastPurchase from '../assets/dashboard/wangen/customers-with-last-purchase-delayed-beyond-average-interval.png';
import potentialRevenue from '../assets/dashboard/wangen/potential-revenue-for-2024-based-on-average-revenue.png';
import revenueContribution from '../assets/dashboard/wangen/revenue-contribution.png';
import topCountriesRevenue from '../assets/dashboard/wangen/top-countries-by-cumulative-revenue.png';
import revenueVsMarketGrowth from '../assets/dashboard/wangen/actual-revenue-growth-vs-projected-market-growth.png';

export const getClientSettings = (email: string) => {
    const emailDomain = email.split('@')[1];
    const subdomain = window.location.hostname.split('.')[0];
    return {
        baseUrl: process.env.API_WANGEN_LLM_BASE_URL,
        showAllSidebarItems: false,
        shouldInitializeChat: false,
        suggestedPrompts: ['Welche Kunden kaufen in regelmäßigen Abständen aber haben schon länger als die durchschnittliche Kaufzeit nicht mehr gekauft.',
            'Welche Kunden haben die letzten Jahre am meisten zum Umsatz beigetragen, aber dieses Jahr noch nicht gekauft?',
            'Welchen Umsatz kann ich für 2024 erwarten?',
            'Welche Markttrends gibt es?',
            'Welche Kundengruppe gibt nach dem ursprünglichen Kauf prozentual am meisten für weitere Produkte und Dienstleistungen aus?',
        ],
        dashboardTitle: '',
        dashboardImages: [
            {
                title: 'Steigender Umsatz von 2021 bis 2023 mit starken saisonalen Trends und einem leichten Abflachen in 2024',
                src: monthlyRevenue
            },
            {
                title: 'Sechs Kunden haben Käufe, die länger zurückliegen als ihr durchschnittliches Kaufintervall und daher proaktiv angegangen werden sollten.',
                src: customerLastPurchase
            },
            {
                title: 'Das Umsatzpotential von 10 Kunden für 2024, die 2024 noch nicht gekauft haben, aber die Jahre davor umsatzstark waren.',
                src: potentialRevenue
            },
            {
                title: 'Ein kleiner Prozentsatz der Kunden trägt zu einem großen Teil des Umsatzes bei, was auf eine hohe Abhängigkeit hinweist.',
                src: revenueContribution
            },
            {
                title: 'Deutschland, Italien und Österreich sind die umsatzstärksten Märkte.',
                src: topCountriesRevenue
            },
            {
                title: 'Während der Umsatz von Wangen die letzten Jahre stärker gewachsen ist als der Gesamtmarkt (7%), bleibt man laut Umsatzforecast 2024 hinter dem Markt zurück.',
                src: revenueVsMarketGrowth
            },
        ],
        // context: 'https://docs.google.com/document/d/1qy0DJSearMT_XurAYkY6ukCQk-VxU-19H2LEFu_3zXc/',
        title: 'WANGEN Tool',
    };
    // Settings for different domains
    //     if (subdomain === 'ace' || emailDomain === 'ace.com') {
    //         return {
    //             baseUrl: process.env.API_ACE_LLM_BASE_URL,
    //             showAllSidebarItems: false,
    //             shouldInitializeChat: false,
    //             suggestedPrompts: ['Wer bist du und wie kannst du mir helfen?',
    //                 'Welche maximalen Lade- und Entladeströme unterstützt das Modell RESS-BS-19-H1?',
    //                 'Welche Abmessungen (B*T*H, mm) hat das Modell RESS-BM-051100-L1',
    //             ],
    //             dashboardImages: null,
    //             title: 'ACE Tool',
    //         };
    //     } else if (subdomain === 'faie' || emailDomain === 'faie.at') {
    //         return {
    //             baseUrl: process.env.API_FAIE_LLM_BASE_URL,
    //             showAllSidebarItems: false,
    //             shouldInitializeChat: false,
    //             suggestedPrompts: ['Hallo, wer bist du und womit kannst du mir helfen?',
    //                 'Was kann ich laut meinen Daten machen um den Umsatz zu steigern?',
    //                 'Gibt es Trends im Markt, die ich berücksichtigen sollte?',
    //                 'In welchen Ländern und Bundesländern mache ich wie viel Umsatz?',
    //                 'Gibt es eine Zahlungsart, die besonders oft genutzt wird und gut funktioniert?',
    //                 'Welche sind meine meistverkauften Produkte?',
    //                 'An welchen Tagen mache ich besonders viel Umsatz und welche Schlüsse sollte ich daraus ziehen?',
    //             ],
    //             dashboardTitle: '10.2 million datapoints analyzed to evaluate the product strategy of FAIE.',
    //             dashboardImages: [
    //                 {
    //                     title: 'Top Produkte nach Umsatz',
    //                     src: topProdukteNachUmsatz
    //                 },
    //                 {
    //                     title: 'Umsatz nach Regionen',
    //                     src: pic
    //                 },
    //                 {
    //                     title: 'Bestellverteilung nach Land',
    //                     src: bestellverteilungNachLand
    //                 },
    //                 {
    //                     title: 'Monatlicher Umsatzvergleich',
    //                     src: monatlicherUmsatzvergleich
    //                 },
    //                 // {
    //                 //     title: 'Top 10 Produkte nach Umsatz',
    //                 //     src: top10Produkte
    //                 // },
    //                 {
    //                     title: 'Zahlungsmethoden und Umsatz',
    //                     src: zahlungsmethodenUndUmsatz
    //                 },
    //                 {
    //                     title: 'Geschlechterverteilung der Kunden',
    //                     src: geschlechterverteilungDerKunden
    //                 },
    //             ],
    //             context: 'https://docs.google.com/spreadsheets/d/1h6lyBDCfaxTlhtSXAAEgiFfyBnFEniH4/edit?usp=sharing&ouid=118075759849623926484&rtpof=true&sd=true',
    //             title: 'FAIE Tool',
    //         };
    //     } else if (subdomain === 'wangen' || emailDomain === 'wangen.at' || email === 'syedwebdev@gmail.com') {
    //         return {
    //             baseUrl: process.env.API_WANGEN_LLM_BASE_URL,
    //             showAllSidebarItems: false,
    //             shouldInitializeChat: false,
    //             suggestedPrompts: ['Welche Kunden kaufen in regelmäßigen Abständen aber haben schon länger als die durchschnittliche Kaufzeit nicht mehr gekauft.',
    //                 'Welche Kunden haben die letzten Jahre am meisten zum Umsatz beigetragen, aber dieses Jahr noch nicht gekauft?',
    //                 'Welchen Umsatz kann ich für 2024 erwarten?',
    //                 'Welche Markttrends gibt es?',
    //                 'Welche Kundengruppe gibt nach dem ursprünglichen Kauf prozentual am meisten für weitere Produkte und Dienstleistungen aus?',
    //             ],
    //             dashboardTitle: '',
    //             dashboardImages: [
    //                 {
    //                     title: 'Steigender Umsatz von 2021 bis 2023 mit starken saisonalen Trends und einem leichten Abflachen in 2024',
    //                     src: monthlyRevenue
    //                 },
    //                 {
    //                     title: 'Sechs Kunden haben Käufe, die länger zurückliegen als ihr durchschnittliches Kaufintervall und daher proaktiv angegangen werden sollten.',
    //                     src: customerLastPurchase
    //                 },
    //                 {
    //                     title: 'Das Umsatzpotential von 10 Kunden für 2024, die 2024 noch nicht gekauft haben, aber die Jahre davor umsatzstark waren.',
    //                     src: potentialRevenue
    //                 },
    //                 {
    //                     title: 'Ein kleiner Prozentsatz der Kunden trägt zu einem großen Teil des Umsatzes bei, was auf eine hohe Abhängigkeit hinweist.',
    //                     src: revenueContribution
    //                 },
    //                 {
    //                     title: 'Deutschland, Italien und Österreich sind die umsatzstärksten Märkte.',
    //                     src: topCountriesRevenue
    //                 },
    //                 {
    //                     title: 'Während der Umsatz von Wangen die letzten Jahre stärker gewachsen ist als der Gesamtmarkt (7%), bleibt man laut Umsatzforecast 2024 hinter dem Markt zurück.',
    //                     src: revenueVsMarketGrowth
    //                 },
    //             ],
    //             context: 'https://docs.google.com/document/d/1qy0DJSearMT_XurAYkY6ukCQk-VxU-19H2LEFu_3zXc/',
    //             title: 'WANGEN Tool',
    //         };
    //     } else if (subdomain === 'degewo' || emailDomain === 'degewo.de') {
    //         return {
    //             baseUrl: process.env.API_DAGEWO_LLM_BASE_URL,
    //             showAllSidebarItems: false,
    //             shouldInitializeChat: false,
    //             suggestedPrompts: ['Hallo, womit kannst du mir helfen?',
    //                 'Erstelle eine öffentliche Ausschreibung für die tägliche Reinigung von Toilettenanlagen in unseren Bürogebäuden in Berlin.',
    //                 'Welches Vergabeverfahren sollten wir wählen, wenn der geschätzte Auftragswert für die Reinigung der Toilettenanlagen 150.000 Euro beträgt?',
    //                 'Welche Eignungsnachweise sollten wir von Bietern verlangen, um sicherzustellen, dass sie qualifiziert sind, die Reinigung unserer Toilettenanlagen durchzuführen?',
    //             ],
    //             dashboardImages: null,
    //             title: 'DEGEWO Tool',
    //         };
    //     } else {
    //         return {
    //             baseUrl: process.env.API_LLM_BASE_URL,
    //             showAllSidebarItems: true,
    //             shouldInitializeChat: true,
    //             suggestedPrompts: ['What is the most urgent recommendation?',
    //                 'What is a ML model and how does it help in my case?',
    //                 'Do you have any further recommendations for me based on the datasets?',
    //                 'What are the main insights from my dataset?',
    //                 'Assess the data quality.',],
    //             dashboardImages: null,
    //             title: null,
    //         };
    //     }
};